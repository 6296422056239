/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~animate.css/animate.min.css";
@import url('https://fonts.googleapis.com/css2?family=Wallpoet&display=swap');
:root {
    --primary: #7544e6;
    --secondary: #76bd21;
    --bg-color: #f3f5f5;
    --text-light: #999999;
    --text-light2: #c5c5c5;
    --text-dark: #4c4c4c;
    --text-black: #000;
    --white: #fffffff0;
    --yelow-color: #ffd234;
    --transparent: rgba(0, 0, 0, 0);
    --red_color: #f93434;
    --event: #f49c12;
}

.bg_color {
    --background: var(--bg-color);
}

.d-flex {
    display: flex !important;
    align-items: center;
    width: 100%;
    .end {
        margin-left: auto !important;
        margin-right: 0 !important;
        display: block;
    }
}

ion-slide,
ion-segment {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.center_img {
    position: relative;
    overflow: hidden;
    img {
        &.crop_img {
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}

.button {
    &.btn {
        width: 100%;
        font-size: .98rem;
        font-weight: 500;
        letter-spacing: .5px !important;
        height: 50px;
        --background: var(--primary);
        color: var(--white);
        margin: 0;
        --box-shadow: none;
        text-transform: unset;
        ion-icon {
            font-size: 1.4rem;
            padding: 0 2px;
        }
        &:hover:not(.disable-hover) {
            --background: var(--primary);
        }
        &.button-outline {
            --background: var(--transparent);
            color: var(--white);
            --border-color: var(--primary);
            --border-width: 1px;
            &:hover:not(.disable-hover) {
                --background: var(--transparent);
            }
        }
        &.button-round {
            --border-radius: 50px !important;
        }
        &.button-block {
            --border-radius: 10px !important;
        }
        &.btn-secondary {
            --background: var(--secondary);
            &:hover:not(.disable-hover) {
                --background: var(--secondary);
            }
        }
    }
}

ion-header {
    z-index: 999;
    &::after {
        display: none !important;
    }
    ion-toolbar {
        --background: var(--primary);
        display: flex;
        align-items: center;
        min-height: 70px !important;
        height: auto;
        overflow: visible !important;
        --border-width: 0 !important;
        ion-buttons {
            position: relative !important;
            margin: 0;
            --padding-end: 0 !important;
            --padding-start: 0 !important;
            ion-back-button {
                --color: var(--white) !important;
                --icon-font-size: 1.6rem !important;
            }
            ion-menu-button {
                --color: var(--text-black) !important;
                --padding-end: 0 !important;
                font-size: 1.8rem !important;
                margin-right: 12px;
                &.ios {
                    font-size: 2rem !important;
                }
            }
            ion-back-button {
                .button-native {
                    padding: 0 !important;
                }
            }
            &.ios {
                padding: 0 10px !important;
            }
        }
        ion-title {
            font-size: 1.2rem;
            font-weight: 400;
            letter-spacing: 0;
            text-transform: none;
            display: block;
            position: absolute;
            left: 0;
            top: -5px;
            overflow: visible !important;
            line-height: 70px;
            color: var(--white);
            text-align: center !important;
            width: 100%;
            padding: 0 15px !important;
        }
    }
    &.bg_transparent {
        background-color: var(--transparent) !important;
        ion-toolbar {
            --background: var(--transparent);
            --ion-color-base: var(--transparent) !important;
        }
    }
    &.ios {
        ion-toolbar ion-buttons ion-back-button {
            right: unset;
        }
    }
}

.form {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: calc(100% - 0px);
    z-index: 99;
    padding: 0 25px;
    ion-list {
        background: var(--transparent) !important;
        margin: 0;
        padding: 0;
    }
    ion-item {
        padding: 12px 20px;
        width: calc(100% - 0px);
        margin: 0 auto;
        --background: var(--transparent);
        align-items: flex-start;
        --inner-padding-end: 0px;
        --inner-min-height: unset !important;
        --min-height: unset !important;
        --padding-start: 0;
        box-shadow: none !important;
        --ripple-color: var(--transparent) !important;
        --background-focused: var(--transparent) !important;
        background: var(--bg-color);
        border-radius: 10px;
        margin-bottom: 11px;
        min-height: 45px !important;
        .item_inner {
            width: 100%;
        }
        ion-label {
            color: var(--text-dark) !important;
            font-size: 0.93rem !important;
            font-weight: 500;
            margin-bottom: 6px !important;
            transform: unset !important;
            letter-spacing: 0px !important;
            max-width: 100% !important;
        }
        .item_inner {
            &.d-flex {
                align-items: flex-start;
            }
        }
        ion-textarea,
        ion-input {
            --padding: 0 !important;
            font-size: 0.9rem !important;
            font-weight: 500 !important;
            letter-spacing: 0;
            --padding-start: 0 !important;
            --padding-end: 0 !important;
            --padding-top: 0 !important;
            --padding-bottom: 0px !important;
            color: var(--black);
            --placeholder-color: var(--text-light) !important;
            --placeholder-opacity: 1;
            --placeholder-font-weight: 500 !important;
            flex: unset !important;
            input {
                --padding: var(--no-padding);
                &.native-input {
                    --padding: var(--no-padding);
                }
            }
        }
        &.item-select {
            ion-select {
                padding: 0;
                font-size: 0.9rem !important;
                font-weight: 500 !important;
                letter-spacing: 0;
                color: var(--black);
                --placeholder-color: var(--text-light);
                --placeholder-opacity: 1;
                max-width: 100%;
                &::part(icon) {
                    display: none !important;
                }
            }
            ion-icon {
                color: var(--text-black);
                font-size: 2rem;
                line-height: 21px;
                height: 20px;
            }
        }
        &.item-datetime {
            ion-datetime {
                padding: 0;
                font-size: 0.98rem !important;
                font-weight: 500 !important;
                letter-spacing: 0;
                color: var(--text-light);
                --placeholder-color: var(--text-light);
                --placeholder-opacity: 1;
                max-width: 100%;
            }
        }
        h5 {
            margin: 0;
            color: var(--primary);
            font-size: 0.85rem;
            height: 45px;
            line-height: 55px;
            letter-spacing: 0.4px;
        }
    }
    ion-row {
        margin: 0 -5px;
    }
}

ion-segment {
    ion-segment-button {
        --ripple-color: var(--transparent) !important;
    }
}

ion-loading {
    .loading-wrapper {
        box-shadow: none !important;
    }
}

.alert-wrapper {
    border-radius: 13px !important;
    min-width: 600px !important;
    max-height: 484px !important;
    height: auto;
    box-shadow: none !important;
    .alert-head {
        background: var(--white);
        padding: 20px 27px 16px 27px;
        h2 {
            //text-transform: capitalize;
            letter-spacing: 1px;
            font-size: 1.2rem;
        }
    }
    .alert-radio-group {
        max-height: 290px !important;
        border-top: none !important;
        .alert-radio-button {
            .alert-button-inner {
                .alert-radio-label {
                    font-size: 1.2rem;
                    color: var(--text-black);
                }
            }
        }
    }
    .alert-button-group {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
        flex-wrap: nowrap;
        .alert-button {
            color: var(--white);
            background: var(--primary);
            height: 50px;
            text-align: center;
            margin: 0;
            width: 100%;
            min-width: 50%;
            .alert-button-inner {
                width: 100%;
                display: block;
                height: fit-content;
                font-size: 1rem;
                text-transform: uppercase;
                letter-spacing: 0.7px;
            }
            &:nth-child(2) {
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1.5px;
                    height: 100%;
                    background-color: var(--white);
                    z-index: 9999;
                    margin: 0 auto;
                }
            }
        }
    }
}

.picker-wrapper {
    min-height: 290px !important;
    .picker-toolbar {
        background: var(--bg-color);
        min-height: 54px;
        height: unset;
        .picker-toolbar-button {
            display: block;
            min-height: 54px;
            line-height: 54px;
            .picker-button {
                text-transform: capitalize;
                font-size: 1.35rem;
                color: var(--primary);
                font-weight: 700;
            }
            &.picker-toolbar-cancel {
                margin-left: 0;
                margin-right: auto;
                .picker-button {
                    color: var(--text-black);
                }
            }
        }
    }
    .picker-columns {
        height: 236px !important;
        .picker-col {
            padding: 0;
            .picker-opts {
                width: auto;
                max-width: unset !important;
                top: 15px;
                .picker-opt {
                    color: var(--text-light);
                    font-size: 1.3rem;
                    font-weight: 700;
                    min-height: 45px;
                    &.picker-opt-selected {
                        color: var(--text-dark);
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
}

ion-loading {
    .loading-wrapper {
        background: var(--bg-color) !important;
        border-radius: 5px !important;
        .loading-content {
            color: var(--text-black) !important;
            font-size: 1.2rem !important;
            font-weight: 500;
        }
    }
}

app-order-info {
    .swiper-pagination {
        bottom: 12px !important;
        text-align: center !important;
        padding: 0 20px;
        .swiper-pagination-bullet {
            opacity: 0.5 !important;
            background: var(--white) !important;
            width: 13px;
            height: 3px;
            border-radius: 0;
            margin: 0 2px !important;
            &.swiper-pagination-bullet-active {
                opacity: 1 !important;
            }
        }
    }
}

app-add-address {
    ion-header ion-toolbar ion-title {
        padding-left: 0 !important;
    }
}

app-add-address {
    ion-searchbar {
        height: 50px;
        padding: 0 !important;
        --placeholder-opacity: 1;
        --placeholder-color: var(--text-dark-primary) !important;
        .searchbar-input-container {
            input {
                box-shadow: none !important;
                padding: 0 !important;
                font-size: 0.95rem !important;
                font-weight: 400 !important;
                position: relative !important;
                text-transform: none !important;
                text-align: justify !important;
            }
        }
    }
}

ion-spinner {
    --spinner-color: var(--primary);
    --color: var(--primary);
}

ion-loading {
    --spinner-color: var(--primary);
}

ion-radio {
    --color-checked: var(--primary);
}

ion-select-popover {
    ion-radio {
        --color-checked: var(--primary);
    }
}

ion-item {
    --ripple-color: var(--transparent) !important;
}

ion-content {
    z-index: 1;
}

ion-segment {
    ion-segment-button {
        --ripple-color: var(--transparent) !important;
    }
}

ion-modal {
    --background: rgb(0 0 0 / 0%) !important;
    --backdrop-opacity: .5 !important;
    background: rgba(0, 0, 0, 0) !important;
}

ion-calendar {
    padding: 0!important;
    .title {
        background: var(--primary)!important;
        padding: 8px 90px 8px 90px !important;
        ion-button {
            color: var(--white)!important;
            text-transform: unset!important;
            letter-spacing: 0!important;
            font-weight: 400!important;
            font-size: 1.15rem!important;
            ion-icon {
                display: none !important;
            }
            &.back {
                background: url('assets/cal/ic_left_icon.png') !important;
                background-position: center!important;
                background-size: cover!important;
                background-repeat: no-repeat!important;
                opacity: 1;
                background-size: 19px !important;
            }
            &.forward {
                background: url('assets/cal/ic_right_icon.png') !important;
                background-position: center!important;
                background-size: cover!important;
                background-repeat: no-repeat!important;
                opacity: 1;
                background-size: 19px !important;
            }
        }
    }
    ion-calendar-week {
        ion-toolbar {
            background: var(--bg-color) !important;
            --min-height: unset !important;
            .week-title {
                height: unset;
                padding: 0 !important;
                li {
                    padding: 12px 0;
                    color: var(--text-black);
                    font-weight: 500;
                    font-size: 1rem;
                }
            }
        }
    }
    ion-calendar-month {
        .days {
            height: 40px !important;
            .days-btn {
                font-weight: 500 !important;
                p {
                    font-size: .9rem !important;
                }
                &[disabled] {
                    p {
                        color: var(--text-light2) !important;
                    }
                }
                &.today {
                    p {
                        color: var(--white)!important;
                        position: relative;
                        z-index: 99;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 30px;
                        height: 30px;
                        background: var(--secondary);
                        margin: auto;
                        border-radius: 50%;
                    }
                }
                &.on-selected {
                    background: none !important;
                    p {
                        color: var(--white)!important;
                        position: relative;
                        z-index: 99;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 30px;
                        height: 30px;
                        background: var(--primary);
                        margin: auto;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
}

.animate__animated {
    --animate-duration: 0s;
    &:nth-child(1) {
        --animate-duration: 0.15s;
    }
    &:nth-child(2) {
        --animate-duration: 0.2s;
    }
    &:nth-child(3) {
        --animate-duration: 0.25s;
    }
    &:nth-child(4) {
        --animate-duration: 0.3s;
    }
    &:nth-child(5) {
        --animate-duration: 0.35s;
    }
    &:nth-child(6) {
        --animate-duration: 0.4s;
    }
    &:nth-child(7) {
        --animate-duration: 0.45s;
    }
    &:nth-child(8) {
        --animate-duration: 0.5s;
    }
    &:nth-child(9) {
        --animate-duration: 0.55s;
    }
    &:nth-child(10) {
        --animate-duration: 0.6s;
    }
    &:nth-child(11) {
        --animate-duration: 0.65s;
    }
    &:nth-child(12) {
        --animate-duration: 0.7s;
    }
    &:nth-child(13) {
        --animate-duration: 0.5s;
    }
    &:nth-child(14) {
        --animate-duration: 0.5s;
    }
    &:nth-child(15) {
        --animate-duration: 0.5s;
    }
}

.swiper-pagination-progressbar {
    height: 5px !important;
    width: calc(100% - 30px) !important;
    margin: 0 auto;
    right: 0;
    border-radius: 50px !important;
    overflow: hidden;
    .swiper-pagination-progressbar-fill {
        background: var(--secondary) !important;
    }
}

ion-app[dir="rtl"] {
    .d-flex .end {
        margin-left: 0 !important;
        margin-right: auto !important;
    }
    ion-header ion-toolbar {
        ion-buttons ion-back-button {
            transform: rotate(180deg) !important;
            right: 8px !important;
        }
        // ion-title {
        //     padding-left: 15px !important;
        //     padding-right: 0 !important;
        // }
    }
    .alert-wrapper .alert-button-group .alert-button:nth-child(2)::after {
        left: unset !important;
        right: 0 !important;
    }
    app-chat-list {
        ion-list ion-item .item_inner .img_box {
            margin-right: 0px!important;
            margin-left: 20px!important;
        }
    }
    app-my-profile {
        .img_container .profile_img_box ion-icon {
            right: unset!important;
            left: 1px!important;
        }
    }
    app-leaderboard {
        ion-list ion-item .item_inner {
            h2 {
                margin-right: 0!important;
                margin-left: 7px!important;
            }
            .user_details .img_box {
                margin-right: 0!important;
                margin-left: 13px!important;
            }
        }
    }
    app-syllabus-topics {
        ion-header ion-toolbar ion-title h3 {
            padding-right: 0!important;
            padding-left: 15px!important;
        }
    }
    app-about-us {
        .scroll_container .item_srcoll {
            &:first-child {
                margin-left: 4px!important;
                margin-right: 0!important;
            }
            &:last-child {
                margin-right: 4px!important;
                margin-left: 0!important;
            }
        }
    }
    app-home {
        ion-list ion-item .item_inner .img_box {
            margin-right: 0 !important;
            margin-left: 15px!important;
        }
    }
    app-faculties ion-list ion-item .item_inner ion-row ion-col p,
    app-tests ion-list ion-item .item_inner ion-row ion-col p {
        padding-left: 0 !important;
        padding-right: 17px !important;
        &.test_date {
            padding-left: 17px !important;
            padding-right: 0 !important;
        }
    }
    app-faculties ion-list ion-item .item_inner ion-row ion-col .message,
    app-tests ion-list ion-item .item_inner ion-row ion-col .start_now {
        padding-right: 0 !important;
        padding-left: 17px !important;
        &::after {
            left: unset !important;
            right: -21px !important;
            transform: rotate(-33deg) !important;
        }
    }
    app-chat {
        ion-header ion-toolbar ion-title .title-inner {
            padding-left: 0!important;
            padding-right: 34px!important;
        }
        .chat_container .chat_box {
            &.received .chat {
                padding-right: 0 !important;
                padding-left: 15px !important;
                margin-right: 0 !important;
                margin-left: auto !important;
                &::before {
                    left: 0 !important;
                    right: unset !important;
                }
                .text_box {
                    text-align: right !important;
                    h2 {
                        padding-right: 0 !important;
                        padding-left: 16px !important;
                    }
                }
            }
            &.send {
                margin-left: 0 !important;
                margin-right: auto !important;
                .chat {
                    padding-left: 0 !important;
                    padding-right: 15px !important;
                    margin-left: 0 !important;
                    margin-right: auto !important;
                    &::before {
                        left: unset !important;
                        right: 0!important;
                    }
                    .text_box {
                        text-align: left !important;
                        h2 {
                            padding-right: 16px !important;
                            padding-left: 0 !important;
                        }
                    }
                }
            }
        }
    }
    app-insight {
        .container .graph_container .graph {
            padding-right: 0 !important;
            padding-left: 12px !important;
            .bar_box {
                .bar {
                    left: unset !important;
                    right: 0 !important;
                }
                &::after {
                    left: unset !important;
                    right: 0 !important;
                }
            }
        }
    }
    app-home {
        .buy_this_app {
            right: unset!important;
            left: 0!important;
            border-radius: 0px 50px 50px 0px;
        }
    }
}

